import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./NavBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const NavigateId = (sectionId) => {
    navigate("/");
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        const scrollBehavior = location.pathname === "/" ? "smooth" : "auto";
        section.scrollIntoView({ behavior: scrollBehavior });
      }
      setShowMenu(false);
    }, 100);
  };

  const btnMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <div className="navBar py-2">
        <FontAwesomeIcon
          onClick={btnMenu}
          icon={faBars}
          style={{ color: "#ffffff" }}
          className="custom-toggler"
        />
        <img
          onClick={() => NavigateId("/")}
          className="mx-auto my-0 h-10 cursor-pointer"
          src="/images/icons/newIconStudio.png"
          alt="Logo"
        />
      </div>
    </>
  );
};

export default NavBar;

import React from "react";
import "./whatWeDo.css";
import WhatWeDoItem from "./WhatWeDoItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SubCard from "./subCards/SubCard";

const What = () => {
  const [showCards, setShowCards] = React.useState(false);

  const [hoveredIndex, setHoveredIndex] = React.useState(null);

  const showCardsHandler = () => {
    setShowCards(!showCards);
    if (showCards) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <div className="pb-60">
      <h2 className="mb-10">WHAT WE DO?</h2>
      <div className="m-auto w-fit grid 2xl:grid-cols-[repeat(4,minmax(0,_336px))] xl:grid-cols-[repeat(3,minmax(0,_336px))] lg:grid-cols-[repeat(2,minmax(0,_336px))] sm:grid-cols-[repeat(1,minmax(0,_336px))] place-items-center gap-8">
        <WhatWeDoItem
          // showCardsHandler={showCardsHandler}
          title="2D Art"
          image={"/images/whatWeDo/2D-Art2.png"}
        />
        <WhatWeDoItem
          image={"/images/whatWeDo/Programming.png"}
          title="Programming"
        />
        <WhatWeDoItem
          title="3D Modeling"
          image={"/images/whatWeDo/iglesia_gotica3-min.gif"}
        />
        <WhatWeDoItem
          title="Characters Design"
          image={"/images/whatWeDo/Character-Design.jpg"}
        />
        <WhatWeDoItem
          title="3D Animation"
          image={"/images/whatWeDo/3DAnim.gif"}
        />
        <WhatWeDoItem
          title="Game Design"
          image={"/images/whatWeDo/gameDesign.png"}
        />
        <WhatWeDoItem
          title="Project Management"
          image={"/images/whatWeDo/projectManager.png"}
        />
        <WhatWeDoItem
          title="Team Building"
          image={"/images/whatWeDo/teamBuilding.png"}
        />
      </div>
      {showCards && (
        <div className="w-full h-full fixed bg-black top-0 z-10">
          <FontAwesomeIcon
            icon={faXmark}
            className="text-white text-4xl absolute top-10 right-10 cursor-pointer"
            onClick={showCardsHandler}
          />
          <p className="font-afacad font-bold text-4xl text-white text-center mt-10">
            2D Art
          </p>
          <div className="flex w-full h-fit justify-center mt-24">
            <SubCard
              hoveredIndex={hoveredIndex}
              index={0}
              onMouseOver={() => setHoveredIndex(0)}
              onMouseOut={() => setHoveredIndex(null)}
              title="Anime"
              image="/images/teamRol/2D-artist.png"
            />
            <SubCard
              hoveredIndex={hoveredIndex}
              index={1}
              onMouseOver={() => setHoveredIndex(1)}
              onMouseOut={() => setHoveredIndex(null)}
              title="PixelArt"
              image="/images/teamRol/2D-artist.png"
            />
            <SubCard
              hoveredIndex={hoveredIndex}
              index={2}
              onMouseOver={() => setHoveredIndex(2)}
              onMouseOut={() => setHoveredIndex(null)}
              title="Concept Art"
              image="/images/teamRol/2D-artist.png"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default What;

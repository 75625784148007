import React from "react";
import "./AboutUs.css";
import AboutData from "../AboutData/AboutData";
import WhatToDo from "../whatWeDo/WhatDo";
import PeopleItem from "./PeopleItem";

const AboutUs = () => {
  return (
    <div className="about-container">
      <div className="aboutUs">
        <AboutData />
      </div>
      <WhatToDo />
    </div>
  );
};

export default AboutUs;

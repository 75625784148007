import React, { useEffect, useState } from "react";
import "./NewsDetails.css";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faLinkedin,
  faFacebook,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";
import { Badges } from "../Badges";
import FeaturedStories from "../FeaturedStories";

const NewsDetails = () => {
  const [showSecondText, setShowSecondText] = useState(false);
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [news, setNews] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch("/data/news.json")
      .then((response) => response.json())
      .then((data) => setNews(data))
      .catch((error) => console.error("Error getting News Data:", error));
  }, []);

  useEffect(() => {
    fetch("/data/news.json")
      .then((response) => response.json())
      .then((data) => {
        const item = data.find((news) => news.id === parseInt(id));
        setNewsItem(item);
      })
      .catch((error) => console.error("Error getting News Data:", error));
  }, [id]);

  if (!newsItem) {
    return (
      <div
        className="spinner-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "3rem",
          backgroundColor: "#000",
          width: "100%",
          height: "80vh",
        }}
      >
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-[#F2F2F2F2]">
      <h1 className="m-auto text-[3rem] font-bold my-10 font-afacad text-[#4A4A4A] w-2/4 text-center">
        {newsItem.title}
      </h1>
      <div className="md:w-2/4 sm:w-3/4 w-3/4 m-auto">
        <div className="w-full ">
          <div
            className={`w-32 h-8 -mt-10 top-8 rounded-tl-xl rounded-br-xl relative flex items-center ${
              Badges[newsItem.badge]
            }`}
          >
            <p className="font-roboto text-sm text-center font-bold m-auto">
              {newsItem.badge}
            </p>
          </div>
          <img
            className="w-full rounded-xl aspect-[932/638] object-cover"
            src={newsItem.backgroundImage}
            alt="default"
          ></img>
        </div>

        <p className="text-2xl font-afacad font-bold text-[#4A4A4A] my-8">
          We are currently working on an entirely new project
        </p>
        <div className="border-b-2 border-solid border-[#D9D9D9]"></div>
        <div className="w-48 mt-2 text-xl flex items-center justify-between text-[#4A4A4A]">
          <p className="font-afacad font-bold">Share on:</p>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
            rel="noreferrer"
            className="ml-1"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href={`https://www.reddit.com/submit?url=${window.location.href}&title=${newsItem.shareTitle}`}
            target="_blank"
            rel="noreferrer"
            className="ml-1"
          >
            <FontAwesomeIcon icon={faReddit} />
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?url=${window.location.href}&title=${newsItem.shareTitle}&source=getout-studio.com&mini=true`}
            target="_blank"
            rel="noreferrer"
            className="ml-1"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${newsItem.shareTitle}&url=${window.location.href}`}
            target="_blank"
            rel="noreferrer"
            className="ml-1"
          >
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
        </div>

        <div className="mb-16 mt-10">
          <h1 className="font-afacad text-2xl text-[#4A4A4A] font-bold">
            {newsItem.header}
          </h1>
          {newsItem.text.split("<br>").map((item, index) => (
            <p className="text-xl mb-5 font-afacad" key={`1${index}`}>
              {item}
            </p>
          ))}

          {!showSecondText && (
            <div className="flex items-center float-right w-60 h-10 bg-[#303030] text-white rounded-full">
              <p
                className="w-full text-center font-normal select-none cursor-pointer"
                onClick={() => setShowSecondText(!showSecondText)}
              >
                More Info
              </p>
            </div>
          )}

          {showSecondText && (
            <div className="bg-[#E9E9E9] rounded-xl p-4 ">
              {newsItem.secondText.split("<br>").map((text, index) => (
                <p
                  className="text-xl mb-5 font-afacad text-[#4A4A4A]"
                  key={`2${index}`}
                >
                  {text}
                </p>
              ))}
              {newsItem.interImage !== "" && (
                <img
                  src={newsItem.interImage}
                  className="mb-4 mx-auto"
                  alt="interImage"
                />
              )}
              {newsItem.header !== "" && (
                <h1 className="font-afacad text-2xl text-[#4A4A4A] font-bold mt-8 mb-7">
                  {newsItem.secondHeader}
                </h1>
              )}
              {newsItem.thirthText !== "" &&
                newsItem.thirthText.split("<br>").map((text, index) => (
                  <p
                    className="text-xl mb-5 font-afacad text-[#4A4A4A]"
                    key={`2${index}`}
                  >
                    {text}
                  </p>
                ))}
            </div>
          )}
        </div>
      </div>
      <FeaturedStories news={news} viewAllButton></FeaturedStories>
      <div className="h-40"></div>
    </div>
  );
};

export default NewsDetails;

import "./App.css";
import NavBar from "./navBar/NavBar";
import Slider from "./containers/Slider/Slider";
import News from "./containers/News/News";
import Footer from "./footer/Footer";
import AboutUs from "./containers/AboutUs/AboutUs";
import AllNews from "./containers/News/AllNews/AllNews";
import NewsDetails from "./containers/News/NewsDetails/NewsDetails";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Slider />
                <News />
                <AboutUs />
              </>
            }
          />
          <Route path="/news" element={<AllNews />} />
          <Route path="/news/:id" element={<NewsDetails />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import "./AllNews.css";
import FeaturedStories from "../FeaturedStories";
import { Tweet } from "react-tweet";

const AllNews = () => {
  const [allNews, setAllNews] = useState([]);
  const [, setFeedBack] = useState([]);

  useEffect(() => {
    fetch("/data/news.json")
      .then((response) => response.json())
      .then((data) => setAllNews(data))
      .catch((error) => console.error("Error getting News Data:", error));
  }, []);

  useEffect(() => {
    fetch("/data/feedback.json")
      .then((response) => response.json())
      .then((data) => setFeedBack(data))
      .catch((error) => console.error("Error getting News Data:", error));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <img
        className="mb-20"
        src="/images/news/section.png"
        alt="news section"
      />
      <FeaturedStories news={allNews}></FeaturedStories>

      <div className="w-fit m-auto text-center mt-10">
        <p className="text-2xl font-bold font-roboto">Social Buzz</p>
        <p className="text-xl font-roboto mt-5">
          Join the conversation on social media
        </p>
      </div>
      <div className="w-3/5 m-auto my-5 flex justify-between">
        <Tweet id="1845215609937711208" />
        <Tweet id="1717758744757445105" />
      </div>
      <div className="h-40"></div>
    </div>
  );
};

export default AllNews;

import React, { useEffect, useState } from "react";
import "./AboutData.css";
import PeopleItem from "../AboutUs/PeopleItem";

const AboutData = () => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetch("/data/team.json")
      .then((response) => response.json())
      .then((data) => {
        const result = [[data[0], data[1]]];
        data = data.slice(2, data.length);
        result.push(data);
        setMembers(result);
      })
      .catch((error) => console.error("Error fetching team data:", error));
  }, []);

  return (
    <div className="w-full">
      <div className="flex flex-col w-full m-auto getout-bg px-5 lg:px-64 pb-10">
        <div className="text-justify mt-10 m-auto w-full">
          <p className="text-white text-3xl text-center">
            Wanna know who we are?
          </p>
          <p className="font-afacad text-2xl text-white mt-10">
            At GetOut Studio, we pride ourselves on our unwavering commitment to
            pushing the boundaries of creativity and technology. With a keen
            focus on innovation, entertainment, and sheer enjoyment, we strive
            to deliver gaming experiences that not only challenge conventions
            but redefine them.
          </p>
          <p className="font-afacad text-2xl text-white mt-5">
            Led by a team of seasoned developers, artists, and storytellers, we
            blend cutting-edge technology with timeless craftsmanship to create
            worlds that players can truly lose themselves in. From captivating
            narratives to exhilarating gameplay mechanics, every aspect of our
            games is meticulously crafted to deliver an unforgettable journey.
          </p>

          <p className="mt-14 text-white text-3xl text-center">Our Team</p>
        </div>
        <div className="mt-10 flex flex-col">
          {members.map((chunk, index) => (
            <div
              key={"chunk" + index}
              className="flex mb-2 justify-center flex-wrap px-10"
            >
              {chunk.map((member, index) => (
                <PeopleItem
                  key={"member" + member.id + index}
                  image={member.image}
                  name={member.name}
                  role={member.principalRole}
                ></PeopleItem>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutData;

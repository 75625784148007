import React, { useEffect, useState } from "react";
import "./Slider.css";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [games, setGames] = useState([]);

  useEffect(() => {
    fetch("/data/games.json")
      .then((response) => response.json())
      .then((data) => setGames(data))
      .catch((error) => console.error("Error getting games data:", error));
  }, []);

  const prevSlide = () => {
    const newIndex = (currentSlide - 1 + games.length) % games.length;
    setCurrentSlide(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentSlide + 1) % games.length;
    setCurrentSlide(newIndex);
  };

  const handleSlideClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="w-full aspect-[1920/700] relative overflow-hidden">
      {games.map((game, index) => (
        <Slide
          key={"slide" + index}
          link={game.link}
          title={game.title}
          currentSlide={currentSlide}
          index={index}
          description={game.description}
          game={game}
        ></Slide>
      ))}
      <button className="prev-btn btn" onClick={prevSlide}>
        <img src="/images/Misc/arrowL.svg" alt="slider arrow left" />
      </button>
      <button className="next-btn btn transition-all" onClick={nextSlide}>
        <img src="/images/Misc/arrowR.svg" alt="slider arrow right" />
      </button>
      <div className="slider-dots">
        {games.map((_, index) => (
          <span
            key={"slideSlot" + index}
            className={index === currentSlide ? "dot active" : "dot"}
            onClick={() => handleSlideClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;

function Slide({ currentSlide, index, description, title, link, game }) {
  return (
    <div
      className={`h-full w-full absolute transform object-cover transition-transform bg-no-repeat bg-cover bg-center ${
        index === currentSlide
          ? "translate-x-0"
          : index < currentSlide
          ? "-translate-x-full"
          : "translate-x-full"
      }`}
      style={{
        backgroundImage: `url(${game.image})`,
      }}
    >
      <div className="select-none object-cover float-right w-2/3 h-full from-transparent to-black bg-gradient-to-r" />
      <div className="absolute top-0 w-2/4 right-5 p-20 text-white drop-shadow-2xl flex-col h-full z-10 hidden lg:flex">
        <p className=" mt-10 text-4xl tracking-widest text-center">{title}</p>
        <p className="font-afacad tracking-tighter font-normal mt-5 text-right text-xl">
          {description}
        </p>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="border-solid border-2 border-white hover:scale-105 rounded-full p-5 px-16 m-auto mt-15 cursor-pointer transition-all duration-300 hover:shadow-[0px_0px_26px_rgba(0,0,0,0.5)] hover:shadow-white"
        >
          <span className="mr-2 select-none">Play Now!</span>
        </a>
      </div>
      <div className="w-full h-full absolute flex lg:hidden">
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="border-solid border-2 border-white hover:scale-105 rounded-full p-5 px-16 m-auto mt-15 cursor-pointer transition-all duration-300 hover:shadow-[0px_0px_26px_rgba(0,0,0,0.5)] hover:shadow-white flex"
        >
          <span className="mr-2 select-none">Play Now!</span>
        </a>
      </div>
    </div>
  );
}

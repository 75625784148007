import React from "react";

export default function PeopleItem({ image, name, role }) {
  return (
    <div className="flex flex-col w-fit">
      <div className="group p-1 self-center w-40 duration-300 transition-all lg:hover:scale-150 hover:z-20 opacity-50 hover:opacity-100">
        <img
          className="aspect-[177.5/215.11] object-cover rounded-lg"
          src={image}
          alt="alt"
        />
        <div className="flex flex-col items-center transition-all -mt-10 opacity-0 group-hover:opacity-100 group-hover:z-30">
          <p className="font-afacad font-bold text-white drop-shadow-[1px_1px_.11px_rgba(0,0,0,.75)]">
            {name}
          </p>
          <p className="font-afacad text-white drop-shadow-[1px_1px_.11px_rgba(0,0,0,.75)]">
            {role}
          </p>
        </div>
      </div>
    </div>
  );
}

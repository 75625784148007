import React from "react";

export default function SubCard({
  onMouseOver,
  index,
  hoveredIndex,
  onMouseOut,
  title,
  image,
}) {
  const bg = {
    backgroundImage: `url(${image})`,
  };
  return (
    <div
      onMouseOver={() => onMouseOver(index)}
      onMouseOut={onMouseOut}
      style={bg}
      className={`w-1/6 aspect-[9/16] my-auto mx-1 rounded-2xl transition-all transform bg-cover bg-center ${
        hoveredIndex != null
          ? hoveredIndex === index
            ? "scale-125 z-10"
            : "opacity-25"
          : ""
      }`}
    >
      <p className="text-white font-afacad text-4xl font-bold p-10">{title}</p>
    </div>
  );
}

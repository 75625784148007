import React from "react";
import "./Footer.css";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full h-32 -mt-32 from-black to-transparent bg-gradient-to-t">
      <div className="w-full h-full px-24 flex items-center">
        <img
          className="h-16"
          src="/images/icons/iconStudio.png"
          alt="logo"
        ></img>
        <Link to="https://www.instagram.com/getout.studio/" target="_blank">
          <FontAwesomeIcon
            className="text-[2.5rem] mr-4 ml-8"
            icon={faInstagram}
            color="white"
          />
        </Link>
        <Link to="https://www.linkedin.com/company/getoutstudio" target="_blank">
          <FontAwesomeIcon
            className="text-[2.5rem] mr-4"
            icon={faLinkedin}
            color="white"
          />
        </Link>
        <img
          className="mr-4"
          src="/images/icons/newMiniIcon.svg"
          alt="minilogo"
        />
        <div className="ml-auto w-1/3 text-right">
          <p className="text-white font-afacad font-normal">
            © 2024 Getout Studio. All Rights Reserved. Getout Studio,
            getout-studio.com and the Getout Studio logo are trademarks of
            Getout Studio in Argentina and/or other countries.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import NewsCard from "./NewsCard";

export default function FeaturedStories({ news, viewAllButton }) {
  return (
    <div className="">
      <div className="w-2/3 m-auto mb-5 font-roboto flex mt-12">
        <img
          alt="concepts"
          src="/images/concepts.jpg"
          className="shadow-2xl hidden sm:block"
        ></img>
        <div className="sm:ml-20 ml-0">
          <p className="text-xl font-bold">Featured Stories</p>
          <p>Explore our curated selection</p>
        </div>
      </div>
      <div className="w-fit m-auto">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-2 py-14 w-fit m-auto">
          {news.slice(0, 3).map((newsItem, index) => (
            <NewsCard
              shadow
              key={`news-${index}`}
              link={`/news/${newsItem.id}`}
              item={newsItem}
            ></NewsCard>
          ))}
        </div>
        {viewAllButton && (
          <div className="mr-auto float-right mb-10">
            <div
              onClick={() => (window.location.href = "/news")}
              className="w-fit px-10 py-3 bg-black text-white rounded-xl cursor-pointer"
            >
              <p className="font-roboto select-none">View All</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

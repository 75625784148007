import React from "react";
import { Link } from "react-router-dom";
import { Badges } from "./Badges";

export default function NewsCard({ link, shadow, item }) {
  const shadowClass = shadow ? "shadow-md" : "";

  return (
    <div
      className={`h-96 w-80 mx-2 rounded-xl ${shadowClass} transition-transform lg:hover:scale-105`}
    >
      <div
        className={`w-28 h-6 -m-3 top-3 relative left-3 rounded-tl-xl rounded-br-xl ${
          Badges[item.badge]
        }`}
      >
        <p className="font-roboto m-0 text-sm text-center pt-[2px] font-bold">
          {item.badge}
        </p>
      </div>
      <img
        className="h-4/5 rounded-t-xl w-full object-cover"
        src={item.backgroundImage}
        alt="news"
      ></img>
      <div className="bg-white h-1/5 w-full rounded-b-xl p-3 text-md">
        <div className="h-full  flex flex-col justify-between">
          <p className="font-roboto">{item.title}</p>
          <Link to={link}>
            <p className="font-bold font-roboto underline">View details</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

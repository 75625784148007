import React from "react";

export default function WhatWeDoItem({ image, title, showCardsHandler }) {
  return (
    <div
      onClick={showCardsHandler}
      className="w-[336px] aspect-[448/320] bg-red-500 rounded-2xl border-solid border-[5px] border-[#CBCBCB] relative from-[#876861] bg-gradient-to-b to-[#372B28] transition-transform lg:hover:scale-150 lg:hover:z-20"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }}
    >
      <p
        className="text-white text-2xl ml-8 mt-8 z-10 relative drop-shadow-2xl"
        style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
      >
        {title}
      </p>
    </div>
  );
}

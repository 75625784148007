import React, { useEffect, useState } from "react";
import "./News.css";
import NewsCard from "./NewsCard";

const News = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch("/data/news.json")
      .then((response) => response.json())
      .then((data) => setNews(data))
      .catch((error) => console.error("Error getting News Data:", error));
  }, []);

  return (
    <div className="containerNews py-10">
      <div className="w-fit rounded-2xl backdrop-blur-2xl bg-transparent m-auto shadow-2xl grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-2  py-14 pb-20 px-12">
        {(news.length > 3
          ? news.slice(news.length - 3, news.length)
          : news
        ).map((newsItem, index) => (
          <NewsCard
            key={`news-${index}`}
            link={`/news/${newsItem.id}`}
            item={newsItem}
          ></NewsCard>
        ))}
        <div className="absolute bottom-5 right-5">
          <div
            onClick={() => (window.location.href = "/news")}
            className="w-fit px-10 py-3 bg-black text-white rounded-xl cursor-pointer"
          >
            <p className="font-roboto select-none">View All</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
